@use '@angular/material' as mat;
@include mat.core();

@import "./assets/variable.scss";

$client-primary: mat.define-palette(mat.$orange-palette);
$client-accent: mat.define-palette(mat.$indigo-palette);
$client-warn: mat.define-palette(mat.$red-palette);

$client-theme: mat.define-light-theme((color: (primary: $client-primary,
        accent: $client-accent,
        warn: $client-warn,
      )));

@include mat.all-component-themes($client-theme);

$client-warn: mat.define-palette(mat.$red-palette);

$client-theme: mat.define-light-theme((color: (primary: $client-primary,
        accent: $client-accent,
        warn: $client-warn,
      )));


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'AvenirRegular' !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilroy", sans-serif;
}

ul {
  @include ul;
}

.project_banner {
  height: 300px;
  width: 100%;
  background: url('/assets/images/illustration.4725054b.svg') left 40px bottom -75px / 380px no-repeat, right -50px bottom -50px / 350px rgb(0, 179, 255);
  background-position: left 40px bottom -75px, right -50px bottom -50px;
  background-repeat: no-repeat;
}

body {
  /*TODO: revert if this causes bugs*/
  /*TODO: as well as reapply class to div in app.component.html */
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #ffffff !important;
  font-family: 'AvenirRegular' !important;
}

.mat-raised-button {
  font-family: 'AvenirRegular' !important;
}

.mat-layout {
  max-width: 80%;
  margin: 2em auto;
  background-color: #ffffff !important;
}

.mat-app-background {
  max-width: 100vw;
  text-align: center;
  margin: 2em 2em 2em 2em;
  /*should we keep text align with mat app bbackground*/
  background-color: #ffffff !important;
}

@media only screen and (max-width: 600px) {

  html,
  body {
    /*TODO: revert if this causes bugs*/
    /*width: 100vw;*/
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  .mat-app-background {
    max-width: 100vw;
    margin: 2em auto;
    /*should we keep text align with mat app bbackground*/
    background-color: #ffffff !important;
  }
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.max-height {
  height: 100%;
}

.app-background-color {
  background-color: #ffffff !important;
}

.mat-drawer-container {
  background-color: #ffffff
}

mat-card {
  /*
  max-width: 80%;
  margin: 2em auto;
  text-align: center;
  */
}

mat-toolbar-row {
  /*
  justify-content: space-between;
  */
}

.done {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white;
}

.basic-container {
  padding: 5px;
}

.version-info {
  font-size: 8pt;
  float: right;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v31/2fcrYFNaTjcS6g4U3t-Y5UEw0lE80llgEseQY3FEmqw.woff2) format('woff2');
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-ExtraBold-1.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'AvenirRegular';
  src: url('./assets/fonts/AvenirRegular.eot');
  src: url('./assets/fonts/AvenirRegular.eot') format('embedded-opentype'),
    url('./assets/fonts/AvenirRegular.woff2') format('woff2'),
    url('./assets/fonts/AvenirRegular.woff') format('woff'),
    url('./assets/fonts/AvenirRegular.ttf') format('truetype'),
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.scroll-header {
  padding-top: 100px !important;
}

.wrapper {
  height: 100vh;
  /* 100vw -> 100% fixes front page bug. */
  width: 100%;
}

.container {
  min-height: 80%;
}

.view-content {
  text-align: center;
  vertical-align: center;
  margin: 0;
  padding: 0;
}

.center-theme {
  text-align: center;
}

.center-w-margin {
  text-align: center;
  margin: auto;
}

.site-footer {}

.pointer {
  cursor: pointer
}

/*snackBarCSS*/
.customSnackBar {
  margin-top: 75px !important;
}

.goodSnackBar {
  background-color: green !important;
}

.badSnackBar {
  background-color: red !important;
}

.msgSnackBar {
  background-color: #1a59db !important;
}

/* chat stuff?
.msgSnackBar {
  background-color: #bfbebe !important;
}
.miniChatSnackBar {
  overflow: hidden;
}
*/

.greyed-error {
  text-align: center;
  padding-top: 100px;
  font-size: 300%;
  color: #888888;
}

/* TODO: global clearfix cleanup */
.clear-fix {
  clear: both;
}

.gt-table,
.gt-table-wo-pointer {
  width: 100%;
}

.gt-table {
  cursor: pointer;
}

.gt-table>td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type,
th.mat-header-cell:last-of-type {
  padding-right: 0px;
}

.gt-by-cell {
  font-size: 80%;
}

.gt-search-container {
  width: 300px;
  padding-left: 0px;
}

.gt-search-field {
  width: 100%;
}

.gt-no-results {
  text-align: center;
  margin-top: 25px;
  font-size: 200%;
  color: #888888;
}

.gt-fullwide-table-row {
  width: 100%;
  height: 56px;
  display: flex;
  position: absolute;
}

.gt-halfwide-table-row {
  width: 50%;
  height: 56px;
  display: flex;
  position: relative;
}

.gt-gutter-fix {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.gt-greyed-main-header {
  background-color: rgba(225, 225, 225, 1);
}

.gt-data-row:hover {
  background-color: rgba(225, 225, 225, 1);
}

.gt-data-row button {
  display: block;
  visibility: hidden;
}

.gt-data-row:hover button {
  display: block;
  visibility: visible;
}

.gt-header {
  font-size: 1.5em;
}

.gt-add-button-container {
  height: 0px;
}

.fake-table {
  height: 48px;
  vertical-align: middle;
  font-size: 14px;
}

.fake-table>td {
  padding-left: 25px;
}

.loading-bar-container {
  height: 0px;
}

.date_picker_route_assignment {
  width: 44%;
  margin: 0 auto;
}

/* make checkbox green in user component */
.completed-checkbox.mat-checkbox-disabled.mat-checkbox-checked:not(.mat-checkbox-indeterminate) .mat-checkbox-background {
  background-color: #00cc00 !important;
}

/*JobStatsComponent*/
.tooltip-newline {
  font-size: 1em !important;
  white-space: pre-line;
}

/* NOTE: this styling was added since a lot of the top divs have this class */
/* Have a better class name */
.example-container {
  height: 100%
}

.front-font {
  font-size: 17px;
  color: rgb(102, 102, 102);
  line-height: 25px;
  font-weight: 400;
}

.portal-container {
  .mat-select-value {
    color: #ECEBEA;
  }

  .mat-select-arrow {
    color: #ECEBEA;
  }

  .mat-form-field-underline {
    background-color: #ECEBEA !important;
  }
}

.mat-menu-panel {
  padding: 10px;
  background: #ECEBEA;
  margin: .5em;
}

.notificationdropdown {
  .mat-tab-label {
    padding: 0 5px;
    min-width: 80px;
    flex-grow: 0 !important;
    border: 1px solid #1A2233;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    opacity: 1;
    height: 38px;
    margin-right: 5px;

    &.mat-tab-label-active {
      background: #1A2233;
      color: #fff;
    }
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-header {
    border-bottom: 1px solid #1A2233;
  }

  .mat-tab-body-content {
    border-left: 1px solid #1A2233;
    border-right: 1px solid #1A2233;
    border-bottom: 1px solid #1A2233;
  }

  .mat-tab-body.mat-tab-body-active {
    animation: fade 0.5s;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

}

// NEW STYLE ADD HERE
.cool-map-wrap {
  margin-top: -40px;

  .mat-drawer-container {
    height: calc(100vh - 64px) !important;
  }

  .map-content {
    height: calc(100vh - 64px) !important;
  }
}

.coolmapBodyScroll {
  overflow: hidden;
}

.search-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 1.25em;
  padding: 0 2% 0 0;

  .datepicker,
  .search {
    width: 100%;
  }

  .mat-mdc-form-field {
    width: 100%;
  }
}

.search,
.search-panel {
  .mat-icon-button {
    top: -6px;

    .mat-icon {
      font-size: 150% !important;
    }
  }
}

.nav_body {
  padding: 0.93em 0px 0.93em 0.62em;
  width: 17.5em;
  position: relative;

  .logo {
    display: none;
  }
}

.mat-datepicker-toggle .mat-datepicker-toggle-default-icon {
  display: none;
}

.mat-datepicker-toggle .mdc-icon-button {
  &:after {
    background: url("./assets/images/calendar.png") no-repeat;
    width: 1.06em;
    height: 1.12em;
    position: absolute;
    content: "";
    top: 15px;
    right: 0;
  }
}
.inputbox .mat-datepicker-toggle .mdc-icon-button {
  &:after {
    background: url("./assets/images/calendar.png") no-repeat;
    width: 1.06em;
    height: 1.12em;
    position: absolute;
    content: "";
    top: -3px;
    right: 0;
  }
}

.datepicker .mat-mdc-form-field,
.search .mat-mdc-form-field {
  width: 100%;
}

.mat-checkbox-inner-container {
  width: 18px !important;
  height: 18px !important;
}

.mat-checkbox-indeterminate .mat-accent .mat-checkbox-background {
  background-color: $blue !important;
}

.mat-checkbox-checked .mat-accent .mat-checkbox-background {
  background-color: #326ad3 !important;
}

.mat-checkbox-checked.mat-checkbox-disabled .mat-checkbox-background {
  background-color: #b0b0b0 !important;
}

perfect-scrollbar {
  height: 62vh !important;

  @media (min-width:1600px) {
    height: 72vh !important;
  }

}

.jobscroll {
  height: 72vh !important;

  @media (min-width:1600px) {
    height: 80vh !important;
  }

  @media (min-width:2500px) {
    height: 85vh !important;
  }

  @media (min-width:3300px) {
    height: 90.5vh !important;
  }
}

.viewroute ul {
  margin: 0 !important;
}

.listing_box {
  perfect-scrollbar {
    height: inherit !important;
    max-height: 96%;

    @media (min-width:1600px) {
      max-height: 93%;
    }

    @media (min-width:2500px) {
      max-height: 94%;
    }
  }
}

.mapboxgl-map {
  height: calc(100vh - 64px) !important;
}

.mapboxgl-canvas {
  width: calc(100vw - 54px) !important;
}

.nav {
  height: calc(100vh - 64px) !important;

  button {
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;

    &:hover {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.22);
    }
  }
}

.jobList ul {
  margin: 0 15px 0 8px !important;
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

.topheadingprt {
  border-bottom: 1px solid #c2c2c2;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: balance;
    max-width: 325px;
    font-size: 1rem;
  }
}

.mat-drawer-content {
  overflow: hidden !important;
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 2%), 0px 16px 24px 2px rgb(0 0 0 / 2%), 0px 6px 30px 5px rgb(0 0 0 / 2%) !important;
}

.scrollprt {
  .cdk-virtual-scroll-content-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .mat-mdc-progress-spinner.mdc-circular-progress {
      max-width: 50px;
      max-height: 50px;
      svg {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.m-40 {
  margin-top: 40px;
}

.m-100 {
  margin-top: 100px;
}

.w-100 {
  width: 100%;
}

.mat-tooltip {
  margin: 4px !important;
}

.col-6 {
  .mat-mdc-form-field {
    width: 100%;
  }
}

.route_listprt {
  .listing_toggle {
    display: flex;
  }
}

.driverList {
  scrollbar-color: $black #f1f1f1;
  scrollbar-width: thin;
}

.driverList::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.driverList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.driverList::-webkit-scrollbar-thumb {
  background: $black;
}

.driverList::-webkit-scrollbar-thumb:hover {
  background: $black;
}

.notes {
  scrollbar-color: $black #f1f1f1;
  scrollbar-width: thin;
}

.notes::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.notes::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.notes::-webkit-scrollbar-thumb {
  background: $black;
}

.notes::-webkit-scrollbar-thumb:hover {
  background: $black;
}

.statusprt {
  display: flex;
  margin-bottom: 10px;

  span {
    flex-direction: row;
    border-radius: 30px;
    margin-left: 10px;

    &.done {
      position: relative;
      bottom: inherit;
      right: inherit;
    }
  }
}

.mat-snack-bar-container {
  background: #008000 !important;

  &.error {
    background: red !important;
  }
}

.mat-simple-snack-bar-content {
  color: #fff;
  display: block;
  text-align: center;
  width: 100%;
}

.routestatus {
  position: absolute;
  border-radius: 30px;
  top: 12px;
  right: 40px;
  font-size: 0.8125rem;
  color: $white;
  text-transform: capitalize;
  font-weight: 500;
  padding: 5px 10px;

  &.routestatus {
    right: 10px;
  }

  &.Ton {
    background: #ff7272;
  }

  &.Load {
    background: #a3c52e;
  }

  &.Hourly {
    background: #ae23d1;
  }
}

.marker {
  .markerPointer {
    width: 15px;
    height: 15px;
    display: flex;
    border-radius: 100%;
    position: relative;
    justify-content: center;
    color: #000;
    align-items: center;
    top: -17px;
    font-weight: bold;
    font-size: 0.9rem;

    b {
      color: #000;
      position: relative;
      z-index: 1;
      font-size: 10px;
      font-weight: 600;
    }

    &:after {
      width: 10px;
      height: 10px;
      background: #fff;
      border-radius: 100%;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .markerSpan {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      position: absolute;
      bottom: -7px;
      content: "";
      left: 20%;
    }
  }

  .markerPointerForDestination {
    width: 15px;
    height: 15px;
    display: flex;
    background: #ffad56;
    border-radius: 100%;
    position: relative;
    justify-content: center;
    color: black;
    align-items: center;
    top: -17px;
    font-weight: bold;
    font-size: 0.9rem;

    b {
      color: #000;
      position: relative;
      z-index: 1;
      font-size: 10px;
      font-weight: 600;
    }

    &:after {
      width: 10px;
      height: 10px;
      background: #fff;
      border-radius: 100%;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .markerSpan {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 10px solid #ffad56;
      position: absolute;
      bottom: -7px;
      content: "";
      left: 20%;
    }
  }
}

.chip-list {
  margin-bottom: 10px;

  .mat-mdc-chip .mat-mdc-chip-action-label, .mdc-evolution-chip__text-label {
    white-space: nowrap;
    text-wrap: balance;
    max-width: 14.87em;
    padding: 0.5em 1.18em 0.5em 0.62em;
    display: inline-block;
    font-size: 0.8rem;
  }
.mdc-evolution-chip-set .mdc-evolution-chip{
  height: auto;
}
}

.status_percent {
  .done {
    position: relative;
    bottom: inherit;
    right: inherit;
  }
}

.mat-form-field-appearance-legacy.disabled .mat-form-field-underline {
  background-color: rgba(0, 0, 0, .1);
}

.mat-form-field-appearance-legacy.disabled .mat-form-field-label {
  color: #eee;
}

.mapboxgl-popup {
  top: -26px !important;
}


.mat-mdc-option.mdc-list-item  .mdc-list-item__primary-text {
   font-size: .8rem;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: balance;
}

.mat-mdc-option {
  height: 2.313em;
}

.mat-mdc-form-field .mat-mdc-form-field-flex .mat-mdc-input-element {
  font-size: 0.9rem;
}

.destination {
  font-size: 0.8rem;

  .duration {
    background: #eee;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 5px;
  }

  .pickprt {
    padding-left: 15px;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 0.75rem;

    b {
      color: #000;
      font-weight: 600;
      position: relative;

      &:before {
        background: #000;
        top: 2px;
        left: -18px;
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 100%;
      }
    }

    &:before {
      background: #000;
      top: 7px;
      left: 0px;
      position: absolute;
      content: "";
      width: 1px;
      height: 100%;
    }
  }

  .dropprt {
    padding-left: 15px;
    position: relative;
    margin-top: 0;
    font-size: 0.75rem;
    margin-bottom: 0;

    b {
      color: #000;
      font-weight: 600;
      position: relative;

      &:before {
        background: #000;
        top: 2px;
        left: -18px;
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 100%;
      }
    }
  }

  span {
    display: block;
    font-size: 0.75rem;

    b {
      color: #000;
      font-weight: 600;
    }
  }
}

.createTxt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  small {
    font-size: 0.6rem;
    font-weight: 600;
  }
}

.routenotes {
  scrollbar-color: $black #f1f1f1;
  scrollbar-width: thin;
}

.routenotes::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.routenotes::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.routenotes::-webkit-scrollbar-thumb {
  background: $black;
}

.routenotes::-webkit-scrollbar-thumb:hover {
  background: $black;
}

.searchprt .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #fff;
}

.searchprt .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #fff;
}

.searchprt {
  .mat-mdc-form-field {
    width: 100%;
  }
}

.searchprt .mat-icon {
  color: #fff;
  font-size: 1.5rem;
}

.searchprt button {
  background: none;
}

.searchprt {
  .mat-mdc-form-field.mat-focused .mat-form-field-ripple {
    background-color: #fff;
  }

  .mat-mdc-form-field.mat-focused .mat-form-field-label {
    color: #fff;
  }

  .mat-mdc-input-element {
    caret-color: #fff;
    color: #fff;
  }
}

.listing_box h2 {
  font-size: 1.2rem;
}

.route_listprt {
  .listing_box {
    height: calc(100vh - 64px) !important;
    top: 64px !important;

    .close {
      display: flex;
    }
  }
}

button {
  cursor: pointer;
}

.driver_list {
  top: 80px !important;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2%;

  h1 {
    margin: 0;
    font-size: 1.2rem;
  }

  button {
    background-color: transparent !important;
    color: #3f51b5 !important;
    border: 1px solid;
    border-radius: 10px;
    box-shadow: none !important;
  }
}

.buttonprt {
  button {
    border: none;
  }
}

.editbtn {
  border: none;
  outline: none;
}


.route_form_area button:disabled {
  background: #eee !important;
  color: #959595 !important;
  border: #959595 !important;
}

.statusarea {
  .infoicon {
    margin-top: 10px;
  }
}

.route_form_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .col-12 {
    width: 100%;
    margin-top: 5px;
    justify-content: space-between;

    .inputbox {
      width: 47%;
    }

    span {
      font-size: 0.875rem;
    }
  }

  .col-6 {
    width: 48%;
    margin-top: 5px;
    justify-content: space-between;

    .inputbox {
      width: 47%;
    }

    span {
      font-size: 0.875rem;
    }
  }

  button {
    border: none;
    outline: none;
    position: absolute;
    right: 0;
    line-height: 1;

    &.savebtn {

      background: $blue;
      padding: 10px 18px 10px 18px;
      color: $white;
      font-size: 0.8125rem;
      border-radius: 30px;
      right: 18px;
      top: 20px;
    }
  }
  .mat-mdc-form-field{
    width: 100%;
  }
}

.route_detail {
  z-index: 9999;
}

.whole_width {
  min-width: 510px !important;
}

.whole_width {
  scrollbar-color: $black #f1f1f1;
  scrollbar-width: thin;
}

.whole_width::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.whole_width::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.whole_width::-webkit-scrollbar-thumb {
  background: $black;
}

.whole_width::-webkit-scrollbar-thumb:hover {
  background: $black;
}

.mat-menu-content button {
  display: flex;
  align-items: center;
}

.mat-menu-content {
  margin: .05em;
}

.mat-menu-content button img {
  width: 30px;
}

.unit_section {

  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #000 !important;
  }
}
.mat-mdc-table, .mat-mdc-header-row, .mat-mdc-row{
  font-family: "Gilroy", sans-serif!important;
}
.mat-mdc-table-sticky {
  top: -2px !important;
}

.logdeatil_table .mat-sort-header-arrow {
  color: #fff !important;
}

.logdeatil_table .mat-mdc-table thead,
.logdeatil_table .mat-mdc-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.logdeatil_table .mat-mdc-header-cell:last-child .mat-sort-header-container {
  justify-content: flex-end;
}

.logdeatil_table .mat-mdc-header-cell .mat-sort-header-container {
  justify-content: center;
}

.orderdatepicker .mat-datepicker-toggle .mat-mdc-icon-button:after {
  background: url("./assets/images/calendar_month.svg") no-repeat;
  width: 17px;
  height: 18px;
  position: absolute;
  content: "";
  top: 20px;
  right: 0;
}

.mat-column-Qty_Sche .mat-sort-header-arrow {
  right: 13px;
}

.select__panel,
.select_option,
.search_material {
  scrollbar-color: #9a9a9a #f1f1f1;
  scrollbar-width: thin;
  overflow: inherit;
  border: 1px solid #CDCDCD;
  box-shadow: none !important;
  max-height: 275px !important;
}

.select__panel::-webkit-scrollbar,
.select_option::-webkit-scrollbar,
.search_material::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.select__panel::-webkit-scrollbar-track,
.select_option::-webkit-scrollbar-track,
.search_material::-webkit-scrollbar-track {
  background: #ecebea;
}

.select__panel::-webkit-scrollbar-thumb,
.select_option::-webkit-scrollbar-thumb,
.search_material::-webkit-scrollbar-thumb {
  background: #9a9a9a;
  border: 2px solid #ecebea;
  border-radius: 30px;
}

.select__panel::-webkit-scrollbar-thumb:hover,
.select_option::-webkit-scrollbar-thumb:hover,
.search_material::-webkit-scrollbar-thumb:hover {
  background: #9a9a9a;
  border: 2px solid #ecebea;
  border-radius: 30px;
}

// .select__panel span:last-child .mat-option {
//   position: sticky;
//   bottom: 0;
//   background-color: #fff;
//   box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.06);
//   color: #0283BA;

//   &:after {
//     background: url("./assets/images/keyboard_double_arrow_right.svg") 0 0 no-repeat;
//     position: absolute;
//     left: 73px;
//     top: 20px;
//     content: "";
//     width: 12px;
//     height: 11px;
//   }
//   &:hover{
//     background-color: #fff;
//   }
// }
.select__panel .mat-mdc-option-active,
.select_option .mat-mdc-option-active,
.search_material .mat-mdc-option-active {
  background-color: rgba(232, 244, 255, 0.6)!important;
  color: #344563;
}

.select__panel .mat-mdc-option:hover:not(.mat-mdc-option-disabled),
.select_option .mat-mdc-option:hover:not(.mat-mdc-option-disabled),
.search_material .mat-mdc-option:hover:not(.mat-mdc-option-disabled) {
  background-color: rgba(232, 244, 255, 0.6)!important;
  color: #344563;
}

.select__panel .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text{
  color: #344563;
}

.select__panel .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple),
.select_option .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple),
.search_material .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple) {
  background-color: rgba(232, 244, 255, 0.6)!important;
  color: #344563;
}

.select__panel .mat-form-field-appearance-legacy .mat-form-field-underline,
.select_option .mat-form-field-appearance-legacy .mat-form-field-underline,
.search_material .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #0000001a !important;
}

.mat-column-ordered_qunatity .mat-sort-header-arrow {
  right: 2px;
}

.mat-column-delivered_quantity .mat-sort-header-arrow {
  right: 2px;
}

.orderdatepicker .mat-form-field-infix {
  top: -5px;
}

@media (min-width:1600px) {
  .mat-column-scheduled_quantity .mat-sort-header-arrow {
    right: inherit;
  }

  .mat-column-ordered_qunatity .mat-sort-header-arrow {
    right: inherit;
  }

  .mat-column-delivered_quantity .mat-sort-header-arrow {
    right: inherit;
  }
}

.add_body {
  position: relative;
}

.pop_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
}

.pop_header .flex {
  display: flex;
  align-items: center;
}

.pop_header h3 {
  font-size: 18px;
  margin: 0 0 0 10px;
  color: #344563;
  font-weight: normal;
}

.pop_header span {
  background: #e8e9ea;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  justify-content: center;
}

.pop_header span .mat-icon {
  color: #000;
}

.formarea {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;
}

.inputfield {
  width: 47%;
  position: relative;
}

.inputfield.w-100 {
  width: 100%;
}

.inputfield.disabled {
  opacity: 0.4;
}

.inputfield .mat-mdc-form-field {
  width: 100%;
}

.body__area {
  padding: 0 20px 60px;
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  scrollbar-color: #f1ba12 #f1f1f1;
  scrollbar-width: thin;
  position: relative;
}

.buttonarea {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #fff;
}

.submit {
  background: #f1ba12;
  color: #000;
  font-weight: 600;
}

.submit:disabled {
  background: #0000001f;
}

.cancel {
  background: #e8e9ea;
  color: #000;
  padding: 0 20px;
  font-weight: 600;
}

.inputfield .mat-form-field.mat-focused .mat-form-field-label {
  color: rgba(52, 69, 99, 0.6);
}

.inputfield .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, .10);
}

.inputfield .mat-form-field.mat-focused .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 1px;
  background-color: #344563;
}

.inputfield .mat-select-arrow {
  position: relative;
  right: 4px;
  top: -4px;
}

.inputfield .mat-form-field input::placeholder {
  color: #949494;
  font-size: 0.875rem;
}

.inputfield .mat-select-value-text,
.inputfield .mat-input-element {
  color: #344563;
  font-size: 0.875rem;
}

.inputfield .mat-select-value {
  top: -3px;
}

.inputfield .mat-form-field-label {
  color: rgba(52, 69, 99, 0.6);
  font-family: "Gilroy", sans-serif;
  letter-spacing: 0.3px;
}

.inputfield .mat-placeholder-required {
  display: none;
}

.badSnackBar {
  background: red !important;
  color: white;
}

/* NEW CSS ADDED FOR ANULAR 17 */

.mat-mdc-text-field-wrapper.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mat-mdc-text-field-wrapper.mdc-text-field{
  padding: 0;
  overflow: inherit;
}
.mdc-floating-label--required{
  display: none;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}
.card .mat-mdc-header-cell{
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-bottom-align{
  padding-bottom: 1.25em;
  height: 0;
}
.mat-mdc-form-field-bottom-align .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-bottom-align .mat-mdc-form-field-error-wrapper{
  padding: 0;
}
.mat-mdc-form-field-error{
  font-size: 75%;
}
.project-item.mat-mdc-raised-button.mat-accent{
  background-color: #1a2233;
}
.mat-mdc-raised-button.mdc-button.mat-primary{
  background-color: #f1ba12;
  color: #000000de;
}
.mat-mdc-menu-panel {
  padding: 0.625em;
  background-color: #ECEBEA;
}
.mat-mdc-menu-item {
  font-family: "Gilroy", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
}
.mat-mdc-header-cell{
  font-size: 0.75rem;
  font-weight: 500;
  color: #0000008a;
}
.mdc-button.mdc-button--raised{
  white-space: nowrap;
  font-family: 'AvenirRegular';
  font-size: 0.875rem;
  padding: 0 1em 0 1em;
}
.mat-mdc-dialog-container .mdc-dialog__surface{
  padding: 1.5em;
}
.quickOrder .mat-mdc-dialog-container .mdc-dialog__surface{
  padding: 0px;
  border-radius: 15px;
}
.mat-mdc-dialog-container .mdc-dialog__title{
  padding: 0;
  font-size: 1.25rem;
}
.mdc-text-field--filled.mdc-text-field--disabled{
  background-color: inherit;
}
.mat-mdc-tooltip {
  font-family: "Gilroy", sans-serif;
  font-size: 0.625rem;
  padding-top: 0.375em;
  padding-bottom: 0.375em;
}
.portal-container .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before{
  border-bottom-color:#fff;
}
.portal-container .mdc-text-field--no-label .mat-mdc-form-field-infix{
  padding: 0.55px 0;
  min-height: 1.87em;
}
.portal-container .mat-mdc-select-value-text{
  color: #fff;
}
.portal-container .mat-mdc-select-arrow{
  color:#fff;
}
.mat-calendar-body-cell .mat-calendar-body-cell-content{
  width: 80%;
  height: 80%;
}
.mat-calendar-body-cell-content.mat-calendar-body-selected{
  background-color: #f1ba12;
}
.mat-mdc-icon-button{
 background-color: inherit;
}
.mat-mdc-icon-button:hover{
  background-color: inherit;
}
.mdc-icon-button__ripple, .mat-mdc-button-ripple{
  display: none;
}
.mat-mdc-paginator-page-size-select .mat-mdc-select-value{
  padding-left: 0.625em;
}
.mat-mdc-paginator-page-size-select .mat-mdc-select-arrow-wrapper{
  padding-right: 0.625em;
}
.route_listprt .listing_box ul li .iconprt span label{
  background: url("./assets/images/blank-check.png") 16px 15px no-repeat;
}
.route_listprt .listing_box ul li .iconprt span input[type="checkbox"]{
  &:checked+label {
    background: url("./assets/images/fill-check.png") 16px 15px no-repeat;
  }
}
.searchprt .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before{
  border-bottom-color:#fff;
}
.searchprt .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after{
  border-bottom-color:#fff;
}
.searchprt .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: #fff;
}
.searchprt .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-line-ripple::before{
  border-bottom-color:#fff;
}
.searchprt input.mat-mdc-chip-input{
  margin-left: 0;
}
.searchprt .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label{
  color: #fff;
}
.select-panel.mat-mdc-select-panel{
  width: 200px;
  position: absolute;
 }
.select-panel.mat-mdc-select-panel .mat-mdc-option .mat-pseudo-checkbox-minimal{
  display: none;
 }
.select-panel.mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text{
  justify-content: space-between;
  width: 100%;
 }
.portal-container .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after{
  border-bottom-color:#000;
}
.portal-container .mat-mdc-form-field.mat-focused .mat-mdc-select-arrow{
  color:#000;
}
.project-dropdown.mat-form-field-disabled{
  .mdc-text-field--filled.mdc-text-field--disabled{
    background-color: inherit;
  }
}
.portal-container .project-dropdown.mat-form-field-disabled .mat-mdc-select-value-text, .portal-container .project-dropdown .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: #c0c0c0;
}
.portal-container .project-dropdown .mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before{
  border-bottom-color:#c0c0c0;
}
