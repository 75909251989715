@import './palette.scss';
@import './mixin.scss';

$black: palette('primary', 50);
$white: palette('primary', 100);
$blue: palette('primary', 200);
$yellow: palette('primary', 300);
$green: palette('primary', 400);
$grey: palette('primary', 500);
$softgrey: palette('primary', 600);
$softwhite: palette('primary', 700);
$buttoncolor: palette('primary', 800);



