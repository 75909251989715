@mixin ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
@mixin img{
    max-width: 100%;
    vertical-align: middle;
}
@mixin justify-start{
    display:flex;
    justify-content: flex-start;
}
@mixin justify-center{
    display:flex;
    justify-content: center;
}
@mixin justify-space-between{
    display:flex;
    justify-content: space-between;
}
@mixin justify-end{
    display:flex;
    justify-content: flex-start;
}
@mixin align-center{
   align-items: center;
}
